<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="10"
        >
          <div
            v-if="hasPermissionToUpdate"
          >
            <b-row
              v-if="isViewMode"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    id="companyEditButton"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(companyId)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>

          <validation-observer
            v-slot="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <!-- Form: Info Form -->
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(onSubmit)"
            >

              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="23"
                      class="mr-50"
                    />
                    <span class="d-inline">Informações</span>
                  </div>
                  <div
                    v-if="!isCreateMode"
                    class="buttons-tooltip-content"
                  >
                    <b-button
                      v-b-tooltip.hover
                      class="mb-50 button-tooltip"
                      variant="outline-primary"
                      size="sm"
                      :to="{ name: 'company-certificates', params: { id: companyId } }"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiFileDocumentCheckOutline"
                      />
                      <span
                        class="font-weight-bolder"
                      >
                        Atestados
                      </span>
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      class="mb-50 ml-50 button-tooltip"
                      variant="outline-primary"
                      size="sm"
                      :to="{ name: 'company-documents', params: { id: companyId } }"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiFileDocumentMultipleOutline"
                      />
                      <span
                        class="font-weight-bolder"
                      >
                        Documentos
                      </span>
                    </b-button>
                  </div>
                </b-card-title>
                <hr class="mt-50">
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <!-- Field: First Name -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- Corporate Name -->
                      <validation-provider
                        #default="validationContext"
                        name="corporate_name"
                        rules="required"
                      >
                        <b-form-group
                          label="Razão Social"
                          label-for="corporate_name"
                        >
                          <b-form-input
                            id="corporate_name"
                            v-model="company.corporate_name"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            placeholder="Razão Social"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- Nome Fantasia -->
                      <validation-provider
                        #default="validationContext"
                        name="name_fantasy"
                        rules="required"
                      >
                        <b-form-group
                          label="Nome Fantasia"
                          label-for="name_fantasy"
                        >
                          <b-form-input
                            id="name_fantasy"
                            v-model="company.name_fantasy"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            trim
                            placeholder="Nome Fantasia"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- CNPJ -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="cnpj"
                        :rules="{
                          required: true,
                          min:14,
                          regex:/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
                        }"
                      >
                        <b-form-group
                          label="CNPJ"
                          label-for="cnpj"
                        >
                          <cleave
                            id="cnpj"
                            v-model="company.cnpj"
                            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            :raw="false"
                            :options="mask.options.cnpjDelimiter"
                            placeholder="00.000.000/0000-00"
                            :readonly="isViewMode"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- Ie -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="ie"
                        :rules="{
                          required: true,
                          regex:/^[0-9]([0-9]*[.\/-][0-9]+)+$/
                        }"
                      >
                        <b-form-group
                          label="Inscrição Estadual"
                          label-for="ie"
                        >
                          <b-form-input
                            id="ie"
                            v-model="company.ie"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            trim
                            :placeholder="isViewMode ? '' : 'Inscrição Estadual'"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="im"
                        :rules="{
                          required: false,
                          regex:/^[0-9]([0-9]*[.\/-][0-9]+)+$/
                        }"
                      >
                        <b-form-group
                          label="Inscrição Municipal"
                          label-for="im"
                        >
                          <b-form-input
                            id="im"
                            v-model="company.im"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            trim
                            :placeholder="isViewMode ? '' : 'Inscrição Municipal'"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- Phone -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="phone_number"
                        :rules="{
                          required: true,
                          min: 14,
                        }"
                      >
                        <b-form-group
                          label="Telefone"
                          label-for="phone_number"
                        >
                          <b-form-input
                            id="phone_number"
                            v-model="company.phone_number"
                            v-mask="getMask('phone_number')"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            :readonly="isViewMode"
                            placeholder="(99) 99999-9999"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- Email -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="email"
                        rules="required|email"
                      >
                        <b-form-group
                          label="E-mail"
                          label-for="e-mail"
                        >
                          <b-form-input
                            id="e-mail"
                            v-model="company.email"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            trim
                            placeholder="exemplo@syslic.com.br"
                            :readonly="isViewMode"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <address-edit-card
                :address="company"
                :is-view-mode="isViewMode"
                :required-fields="requiredFields"
              />

              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="mt-2 ml-2 mb-0"
                >
                  <feather-icon
                    icon="SlidersIcon"
                    size="23"
                    class="mr-50"
                  />
                  <span class="d-inline">Parâmetros de Precificação</span>
                </b-card-title>
                <hr>
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="margin_min"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Margem Mínima"
                          label-for="margin_min"
                        >
                          <b-input-group>
                            <template #append>
                              <b-input-group-text>%</b-input-group-text>
                            </template>
                            <cleave
                              id="margin_min"
                              v-model="company.margin_min"
                              name="margin_min"
                              :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                              :options="options.percentage"
                              placeholder="0,00"
                              :readonly="isViewMode"
                              @blur="adjustDecimalPlaces('margin_min', 2)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="fixed_cost"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Custo Fixo"
                          label-for="fixed_cost"
                        >
                          <b-input-group>
                            <template #append>
                              <b-input-group-text>%</b-input-group-text>
                            </template>
                            <cleave
                              id="fixed_cost"
                              v-model="company.fixed_cost"
                              name="fixed_cost"
                              :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                              :options="options.percentage"
                              placeholder="0,00"
                              :readonly="isViewMode"
                              @blur="adjustDecimalPlaces('fixed_cost', 2)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="tax_aliquot"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Alíquota de Imposto"
                          label-for="tax_aliquot"
                        >
                          <b-input-group>
                            <template #append>
                              <b-input-group-text>%</b-input-group-text>
                            </template>
                            <cleave
                              id="tax_aliquot"
                              v-model="company.tax_aliquot"
                              name="tax_aliquot"
                              :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                              :options="options.percentage"
                              placeholder="0,00"
                              :readonly="isViewMode"
                              @blur="adjustDecimalPlaces('tax_aliquot', 2)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="difference"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Defasagem"
                          label-for="difference"
                        >
                          <b-input-group>
                            <template #append>
                              <b-input-group-text>%</b-input-group-text>
                            </template>
                            <cleave
                              id="difference"
                              v-model="company.difference"
                              name="difference"
                              :class="validationContext.errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                              :options="options.percentage"
                              placeholder="0,00"
                              :readonly="isViewMode"
                              @blur="adjustDecimalPlaces('difference', 2)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          <b-popover
                            :target="`difference`"
                            triggers="hover"
                            placement="bottomright"
                          >
                            <span><b>Defasagem Máxima: </b> Parâmetro que determina se o preço do produto está defasado (custo estimado na licitação versus custo atual do item). Caso a diferença seja maior que o estabelecido será necessário reajustar o contrato.</span>
                          </b-popover>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-row
                class="mt-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="companySaveButton"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    v-if="!isViewMode && isCreateMode"
                    id="companyCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to="{ name: 'company' }"
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    v-if="isViewMode || !isCreateMode"
                    id="companyCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to="{ name: 'company' }"
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import log from 'loglevel'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BFormInvalidFeedback,
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BPopover,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  mdiFileDocumentMultipleOutline,
  mdiFileDocumentCheckOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import router from '@/router'
import AddressEditCard from '@/components/address/AddressEditCard.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    Cleave,
    AddressEditCard,
    SpinLoader,
    SvgIcon,
    BPopover,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    let isViewMode = true
    let isCreateMode = false

    const company = {
      id: '',
      corporate_name: '',
      name_fantasy: '',
      cnpj: '',
      ie: '',
      im: '',
      email: '',
      phone_number: '',
      address: '',
      address_type: '',
      address_type_set: '',
      neighborhood: '',
      neighborhood_type: '',
      neighborhood_type_set: '',
      complement: '',
      number: '',
      city: '',
      city_set: '',
      state: '',
      state_set: '',
      country: 1,
      country_set: {
        id: 1,
        name: 'Brasil',
        code: '1058',
      },
      zip_code: '',
      fixed_cost: '',
      margin_min: '',
      tax_aliquot: '',
    }

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const isToUpdateCompany = router.currentRoute.params.id !== undefined

    const requiredFields = []

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.company)

    const isReady = {
      companyLoaded: false,
    }

    const isLoaded = false

    const options = {
      percentage: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    return {
      required,
      isViewMode,
      isCreateMode,
      isToUpdateCompany,
      company,
      requiredFields,
      refFormObserver,
      getValidationState,
      companyId: null,
      isReady,
      isLoaded,
      options,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.company.can_delete
    },
  },
  created() {
    this.companyId = router.currentRoute.params.id

    if (router.currentRoute.params.id !== undefined) {
      syslic
        .company
        .fetchCompany(router.currentRoute.params.id)
        .then(response => {
          this.company = response.data
          this.onReady('companyLoaded')
        })
        .catch(error => {
          if (error.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.onReady('companyLoaded')
        })
    } else {
      this.onReady('companyLoaded')
    }
  },
  methods: {
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.company[field]) {
        if (this.company[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
    createCompany() {
      syslic
        .company
        .addCompany(this.company)
        .then(response => {
          this.companyId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empresa adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          let errorVariant = 'danger'
          this.text_error = 'Não foi possível adicionar a empresa, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('duplicate key value violates unique constraint "unique_company_cnpj_account"')) {
            errorVariant = 'warning'
            this.text_error = 'Não foi possivel cadastrar a empresa porque já existe uma empresa cadastrada com o mesmo CNPJ.'
          } else if ('cnpj' in error.data) {
            if (error.data.cnpj[0].includes('is not valid cnpj')) {
              this.text_error = 'O CNPJ informado não é valido. Por favor informe um CNPJ valido para cadastrar uma empresa.'
            } else if (error.data.cnpj[0].includes('company with this cnpj already exists.')) {
              this.text_error = 'Já existe uma empresa cadastrada com este CNPJ.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar uma nova empresa.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: errorVariant,
              timeout: false,
            },
          })
        })
    },
    updateCompany() {
      syslic
        .company
        .updateCompany(router.currentRoute.params.id, this.company)
        .then(() => {
          log.info('Update Company')
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empresa atualizada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          let errorVariant = 'danger'
          this.text_error = 'Não foi possível atualiazar a empresa, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('duplicate key value violates unique constraint "unique_company_cnpj_account"')) {
            this.text_error = 'Não foi possivel atualizar esta empresa porque já existe uma empresa cadastrada com o mesmo CNPJ.'
          } else if ('cnpj' in error.data) {
            if (error.data.cnpj[0].includes('is not valid cnpj')) {
              errorVariant = 'warning'
              this.text_error = 'O CNPJ informado não é valido. Por favor informe um CNPJ valido para atualizar a empresa.'
            } else if (error.data.cnpj[0].includes('company with this cnpj already exists.')) {
              this.text_error = 'Já existe uma empresa cadastrada com este CNPJ.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualiazar a empresa.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: errorVariant,
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createCompany()
      } else {
        this.updateCompany()
      }
    },
    deleteAccount(id) {
      syslic
        .company
        .deleteCompany(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Empresa removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'company' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover esta empresa, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta empresa é referenciada em alguma licitação, por este motivo não será possível exclui-la.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover empresa.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover essa empresa?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAccount(item)
          }
        })
    },
    enableEdition() {
      router.push({ name: 'company-edit' })
    },
    disableEdition() {
      this.isCreateMode = false
      router.push({
        name: 'company-detail',
        params: {
          id: this.companyId,
        },
      })
        .catch(() => {})
    },
    onReady(module) {
      let isLoaded = true
      this.isReady[module] = true
      Object.keys(this.isReady).forEach(key => {
        if (!this.isReady[key]) {
          isLoaded = false
        }
      })
      this.isLoaded = isLoaded
    },
    adjustDecimalPlaces(field, places) {
      const decimalReference = 10 ** places
      const num = this.company[field]

      if (num !== '') {
        this.company[field] = ((num * decimalReference) / decimalReference).toFixed(places)
      }
    },
  },
  setup() {
    const toast = useToast()

    const mask = {
      options: {
        cepDelimiter: {
          delimiters: ['-'],
          blocks: [5, 3],
          uppercase: true,
          required: true,
        },
        cnpjDelimiter: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
      },
    }

    return {
      toast,
      mask,
      mdiFileDocumentMultipleOutline,
      mdiFileDocumentCheckOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

.buttons-tooltip-content{
  text-align: end;
}

.button-tooltip {
  width: 150px;
}

@media(max-width: 370px) {
  .card-title {
    flex-direction: column;
  }

  .buttons-tooltip-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  .button-tooltip {
    margin-left: 0 !important;
    width: 100%;
  }
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
